import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../utils/config";
import {IChoiceData} from "../../model/IChoice";
import {ChoiceActions, ChoiceActionTypes} from "../../types/choice";



export const choiceActionsCreator = {

    setCurrency: (data: IChoiceData) => (dispatch: Dispatch<ChoiceActions>) => {
        dispatch({type: ChoiceActionTypes.SET_CURRENCY, payload: data})
    },
    fetchChoice: (data: IChoiceData, invoiceId: string) => async (dispatch: Dispatch<ChoiceActions>) => {
        try {
            dispatch({type: ChoiceActionTypes.FETCH_CHOICE})
            const response = await axios.get(`hybridAcquiring/invoice/${invoiceId}/choice`, {
                params: data,
                baseURL:
            config.url.API_URL
            })
            if(response.data.result === 0) {
                dispatch({type: ChoiceActionTypes.FETCH_CHOICE_SUCCESS, payload: response.data})
            } else if (response.data.result === 400 || response.data.result  > 401 ) {
                dispatch({type: ChoiceActionTypes.FETCH_CHOICE_ERROR, payload: `ERROR!!! ${response.data.desc}`})
            }
        }catch (e: any) {
            dispatch({type: ChoiceActionTypes.FETCH_CHOICE_ERROR, payload: `ERROR!!! acquiring/choice/: ${JSON.stringify(e.response)}`})
        }
    },
}