
import React from 'react';
import {createTheme, ThemeProvider} from "@mui/material";
import {Route, Switch} from "react-router-dom";
import {RouteNamesFortune} from "../../routes";
import {Colors} from "../../styles/styles";
import FortuneMerchantTest from "../testFortune/FortuneMerchantTest";
import SuccessPay from "../testFortune/SuccessPay";
import Merchant from "../../pages/Merchant";

const TestMerchant = () => {
    const themeFortune = createTheme({
        palette: {
            primary: Colors.fortune.primary,
            secondary: Colors.fortune.secondary
        },
    });
    return (
        <ThemeProvider theme={themeFortune}>
            <Switch>
                <Route
                    path={"/"}
                    component={Merchant}
                    exact={true}
                />
                <Route
                    path={"/merchant-page"}
                    component={Merchant}
                    exact={true}
                />
                <Route
                    path={RouteNamesFortune.COMPLETED}
                    component={SuccessPay}
                    exact={true}
                />
                <Route
                    path={RouteNamesFortune.MERCHANT_SIMPLISTIC}
                    component={FortuneMerchantTest}
                    exact={true}
                />
            </Switch>
        </ThemeProvider>
    );
};

export default TestMerchant;