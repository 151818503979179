const DEV = {
    url: {
        API_URL: 'https://api-dev.hybrid-acquiring.eu/api',
        URL_REGISTRATION: 'https://reg-dev.bertes.eu/',
        URL_FORTUNE_LOGIN: 'https://dev.frtn.trade/',
        URL_EVERCASH_LOGIN: 'https://pay-dev.evercash.eu/',
        // URL_FORTUNE_LOGIN: 'http://localhost:3000/',
        URL_STREAM_REDIRECT_JP: 'https://dev.jackpayments.com/login?initId=',
    }
}

const STG = {
    url: {
        API_URL: 'https://api-stg.hybrid-acquiring.eu/api',
        URL_REGISTRATION: 'https://reg-stg.bertes.eu/',
        URL_FORTUNE_LOGIN: 'https://stg.frtn.trade/',
        URL_EVERCASH_LOGIN: 'https://pay-stg.evercash.eu/',
        URL_STREAM_REDIRECT_JP: 'https://dev.jackpayments.com/login?initId=',
    }
}

const PROD = {
    url: {
        API_URL: 'https://api.hybrid-acquiring.eu/api',
        URL_REGISTRATION: 'https://reg.bertes.eu/',
        URL_FORTUNE_LOGIN: 'https://pro.frtn.trade/',
        URL_EVERCASH_LOGIN: 'https://pay.evercash.eu/',
        URL_STREAM_REDIRECT_JP: 'https://dev.jackpayments.com/login?initId=',
    }
}
export const config = process.env.REACT_APP_STAGE === 'production' ? PROD : process.env.REACT_APP_STAGE === 'stg' ? STG : DEV;
